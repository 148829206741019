import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import '../tailwind.css';
import './index.css';
import CurriculumList from './curriculumList';
import ProgramsList from './programsList';

function Programs() {
  return (
    <div className="bg-[#FAFAFA] pt-10 md:pt-16">
        <div className="flex flex-col justify-start items-center w-screen neuron-background">
            <div className="w-11/12 text-center">
                <h2 className="text-white font-bold text-4xl p-10">
                    Learn to Code the Fun Way!
                </h2>
                <p className="text-white text-2xl mb-2">
                    <TypeAnimation
                        sequence={[
                            // Same substring at the start will only be typed once, initially
                            'Every child is unique.',
                            2000,
                            'Every child is special.',
                            2000,
                            'Every child is talented.',
                            2000,
                            'Every child is curious.',
                            2000,
                            'Every child is creative.',
                            2000,
                        ]}
                        speed={150}
                        style={{ color: 'white'}}
                        repeat={Infinity}
                        />
                </p>
                <p className="text-white text-2xl mb-2">
                    Fill your child's curiosity with our programs.
                </p>
                <p className="text-white text-2xl mb-4">
                    From Little Coder to Senior Coder, we have a program for every age group.
                </p>
            </div>
        </div>
        {/* ---- Curriculum ----- */}
        <CurriculumList />

        {/* ---- Programs ----- */}
        <ProgramsList />

    </div>
  );
}

export default Programs;