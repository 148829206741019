import React from 'react';

function ThankYou() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Thank You!</h2>
        <p className="text-center">Your registration was successful.</p>
        <p className="text-center">We will get back to you shortly.</p>
        <p className="text-center">Please check your email/whatsapp for more details.</p>
        <br />
        <p className="text-center"><a href="/">Click here to go back to the homepage.</a></p>
      </div>
    </div>
  );
}

export default ThankYou;