import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactCountryFlag from "react-country-flag";
import '../tailwind.css';

function Questionnaire() {
  const [step, setStep] = useState(1);
  const [childName, setChildName] = useState('');
  const [childAge, setChildAge] = useState('');
  const [ownPC, setOwnPC] = useState('');
  const [language, setLanguage] = useState('');
  const [parentName, setParentName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('62');
  const [preferredDate, setPreferredDate] = useState('');
  const [preferredHour, setPreferredHour] = useState('');
  const [timeZoneLabel, setTimeZoneLabel] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getTimeZoneLabel = () => {
      const offset = new Date().getTimezoneOffset() / -60;
      switch (offset) {
        case 7:
          return 'WIB';
        case 8:
          return 'WITA';
        case 9:
          return 'WIT';
        default:
          return `GMT${offset >= 0 ? '+' : ''}${offset}`;
      }
    };

    const timeZoneLabel = getTimeZoneLabel();
    setTimeZoneLabel(timeZoneLabel);
  }, []);

  const handleNext = (e) => {
    e.preventDefault();
    setStep(step + 1);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const localDateTime = new Date(`${preferredDate}T${preferredHour}:00:00`);
    const gmtPlus7DateTime = new Date(localDateTime.getTime() + (7 * 60 * 60 * 1000));
    const demoTime = gmtPlus7DateTime.toISOString().replace('T', ' ').substring(0, 19);

    const formData = {
      student_name: childName,
      parent_name: parentName,
      email: email,
      phone_number: `${countryCode}${phone}`,
      age: childAge,
      demo_time: demoTime,
      own_pc: ownPC === 'yes' ? 'True' : 'False',
      preferred_language: language === 'english' ? 'English' : 'Indonesia',
    };

    try {
      const response = await fetch('https://api.codelands.org/api/v1/demo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      setResponseMessage(result.message);

      if (response.ok) {
        console.log('Form submitted successfully');
        navigate('/thank-you'); // Redirect to Thank You page
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setResponseMessage('Internal server error');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Get Your Free Class</h2>
        {responseMessage && (
          <div className={`mb-4 p-4 rounded ${responseMessage === 'Form data received successfully' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {responseMessage}
          </div>
        )}
        {step === 1 && (
          <form onSubmit={handleNext} className="space-y-4">
            <div>
              <label htmlFor="childName" className="block text-sm font-medium text-gray-700">Child's Name</label>
              <input
                type="text"
                id="childName"
                name="childName"
                value={childName}
                onChange={(e) => setChildName(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="childAge" className="block text-sm font-medium text-gray-700">Child's Age</label>
              <input
                type="number"
                id="childAge"
                name="childAge"
                value={childAge}
                onChange={(e) => setChildAge(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="parentName" className="block text-sm font-medium text-gray-700">Parent's Name</label>
              <input
                type="text"
                id="parentName"
                name="parentName"
                value={parentName}
                onChange={(e) => setParentName(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone Number</label>
              <div className="flex">
                <select
                  id="countryCode"
                  name="countryCode"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  className="mt-1 block w-1/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="62">
                    <ReactCountryFlag countryCode="ID" svg style={{ marginRight: '8px' }} />
                    +62
                  </option>
                  <option value="1">
                    <ReactCountryFlag countryCode="US" svg style={{ marginRight: '8px' }} />
                    +1
                  </option>
                  <option value="44">
                    <ReactCountryFlag countryCode="GB" svg style={{ marginRight: '8px' }} />
                    +44
                  </option>
                  <option value="91">
                    <ReactCountryFlag countryCode="IN" svg style={{ marginRight: '8px' }} />
                    +91
                  </option>
                  {/* Add more country codes as needed */}
                </select>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="mt-1 block w-3/4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Next
              </button>
            </div>
          </form>
        )}
        {step === 2 && (
          <form onSubmit={handleNext} className="space-y-4">
            <div>
              <label htmlFor="ownPC" className="block text-sm font-medium text-gray-700">Does your child have access to a computer or laptop?</label>
              <div className="mt-1">
                <div>
                  <input
                    type="radio"
                    id="ownPCYes"
                    name="ownPC"
                    value="yes"
                    onChange={(e) => setOwnPC(e.target.value)}
                    className="mr-2"
                    required
                  />
                  <label htmlFor="ownPCYes" className="text-sm font-medium text-gray-700">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="ownPCNo"
                    name="ownPC"
                    value="no"
                    onChange={(e) => setOwnPC(e.target.value)}
                    className="mr-2"
                    required
                  />
                  <label htmlFor="ownPCNo" className="text-sm font-medium text-gray-700">No</label>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="language" className="block text-sm font-medium text-gray-700">Preferred Language</label>
              <select
                id="language"
                name="language"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              >
                <option value="">Select language</option>
                <option value="english">English</option>
                <option value="bahasa">Bahasa Indonesia</option>
              </select>
            </div>
            <div className="flex justify-between">
              <button
                onClick={handleBack}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Back
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Next
              </button>
            </div>
          </form>
        )}
        {step === 3 && (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="preferredDate" className="block text-sm font-medium text-gray-700">Preferred Date</label>
              <input
                type="date"
                id="preferredDate"
                name="preferredDate"
                value={preferredDate}
                onChange={(e) => setPreferredDate(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="preferredHour" className="block text-sm font-medium text-gray-700">
                Preferred Time (Hour) - Time Zone: {timeZoneLabel}
              </label>
              <select
                id="preferredHour"
                name="preferredHour"
                value={preferredHour}
                onChange={(e) => setPreferredHour(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              >
                <option value="">Select time</option>
                {[...Array(24).keys()].map(hour => (
                  <option key={hour} value={hour < 10 ? `0${hour}` : hour}>
                    {hour < 10 ? `0${hour}` : hour}:00
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-between">
              <button
                onClick={handleBack}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Back
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default Questionnaire;