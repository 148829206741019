import React from 'react';
import { Link } from 'react-router-dom';
import '../tailwind.css';
import './programsList.css';

const buttonClasses = "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800";

const curriculum = [
    {
        title: "Code.org",
        description: "Learn the basics of coding through fun and interactive activities.",
        learningList: [
            "Sequencing",
            "Loops",
            "Conditional statements",
            "Events",
            "Variables",
            "Functions"
        ],
        age: "Ages 4+",
        image: "codeorg/codeorg.png",
        link: "/programs/codeorg"
    },
    {
        title: "Scratch",
        description: "Visual programming language for kids. Create stories, games, and animations.",
        learningList: [
            "Visual programming",
            "Creative thinking",
            "Game development"
        ],
        age: "Ages 4+",
        image: "scratch/scratch.png",
        link: "/programs/scratch"
    },
    {
        title: "Game Development",
        description: "Learn how to create games using Unity game engine.",
        learningList: [
            "Unity game engine",
            "Game development",
            "Creative thinking"
        ],
        age: "Ages 8+",
        image: "gamedev/gamedev.png",
        link: "/programs/game-development"
    },
    {
        title: "Web Development",
        description: "Learn HTML, CSS, and JavaScript. Create websites and web applications.",
        learningList: [
            "HTML",
            "CSS",
            "JavaScript",
            "Web development"
        ],
        age: "Ages 8+",
        image: "webdev/webdev.png",
        link: "/programs/web-development"
    },
    {
        title: "Python",
        description: "Learn Python programming language. Create games, apps, and websites.",
        learningList: [
            "Python programming",
            "Game development",
            "Web development"
        ],
        age: "Ages 10+",
        image: "python/python.png",
        link: "/programs/python"
    },
    {
        title: "App Development",
        description: "Learn how to create mobile apps for Android and iOS devices.",
        learningList: [
            "App development",
            "Android",
            "iOS"
        ],
        age: "Ages 10+",
        image: "appdev/android.png",
        link: "/programs/app-development"
    },
    {
        title: "Cybersecurity",
        description: "Learn about cybersecurity and how to protect yourself online.",
        learningList: [
            "Cybersecurity",
            "Online safety",
            "Ethical hacking"
        ],
        age: "Ages 14+",
        image: "cybersecurity/cybersecurity.png",
        link: "/programs/cybersecurity"
    },
    {
        title: "Artificial Intelligence",
        description: "Learn about AI and machine learning. Create intelligent systems.",
        learningList: [
            "Artificial Intelligence",
            "Machine learning",
            "Data analysis"
        ],
        age: "Ages 14+",
        image: "ai/pytorch.png",
        link: "/programs/artificial-intelligence"
    },
    {
        title: "Data Science",
        description: "Learn about data science and data analysis. Create data visualizations.",
        learningList: [
            "Data science",
            "Data analysis",
            "Data visualization"
        ],
        age: "Ages 14+",
        image: "datascience/datascience.png",
        link: "/programs/data-science"
    },
    {
        title: "Robotics",
        description: "Learn about robotics and how to build and program robots.",
        learningList: [
            "Robotics",
            "Programming",
            "Engineering"
        ],
        age: "Ages 14+",
        image: "robotics/robotics.png",
        link: "/programs/robotics"
    }
];

function ProgramsList() {
  return (
    <div className="bg-[#FAFAFA] py-10">
        {/* ---- curriculum ----- */}
        <div className="flex justify-center items-center w-screen">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-10/12 m-2">
                <div className="col-span-1 md:col-span-2 lg:col-span-3 text-center">
                    <h1 className="text-[#333] font-bold text-4xl m-10">
                        Our programs
                    </h1>
                </div>

                {curriculum.map((program, index) => (
                    <div key={index} className="flex flex-col border-2 border-gray-200 shadow-md p-4 hover:shadow-lg transition-shadow duration-300">
                        <div className="flex w-full justify-between items-center mb-4">
                            <div className="flex-1">
                                <h2 className="text-[#333] text-2xl m-4 font-semibold text-left">
                                    {program.title} <br></br>
                                    <span className="text-sm text-gray-500">{program.age}</span>
                                </h2>
                            </div>
                            <div className="flex-1 flex justify-center">
                                <img src={process.env.PUBLIC_URL + '/programs/' + program.image} alt={program.title} className='w-1/2 h-auto' />
                            </div>
                        </div>
                        <div className="flex flex-col items-start">
                            <p className="text-[#333] text-lg m-4 text-left">
                                {program.description}
                            </p>
                            <div className="flex flex-wrap m-4">
                                {program.learningList.map((item, i) => (
                                  <span key={i} className="bg-blue-100 text-blue-700 text-sm font-semibold mr-2 mb-2 px-3 py-1 rounded-full">
                                    {item}
                                  </span>
                                ))}
                            </div>
                            <Link to={program.link} className={`${buttonClasses} m-4`}>
                                Learn More
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
  );
}

export default ProgramsList;