import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Footer from './Footer';
import Navbar from './navbar';
import Homepage from './homepage';

import Programs from './programs';
import LittleCoder from './programs/littlecoder';
import JuniorCoder from './programs/juniorcoder';
import SeniorCoder from './programs/seniorcoder';
import CodeorgClass from './programs/codeorg';
import PythonClass from './programs/python';

import Pricing from './about/pricing';
import SpecialOffer from './about/specialOffer';
import AboutUs from './about';
import Contact from './about/contact';
import Career from './about/career';
import FaqPage from './about/faq';
import LoginPage from './user/login';
import Questionnaire from './user/demo';
import ThankYou from './user/thankyou';
import FloatingButton from './FloatingButton';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Add the ScrollToTop component here */}
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/curriculum/little-coder" element={<LittleCoder />} />
        <Route path="/curriculum/junior-coder" element={<JuniorCoder />} />
        <Route path="/curriculum/senior-coder" element={<SeniorCoder />} />
        <Route path="/programs/codeorg" element={<CodeorgClass />} />
        <Route path="/programs/python" element={<PythonClass />} />

        {/* About route*/}
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/special-offer" element={<SpecialOffer />} />

        {/* User login route */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/demo" element={<Questionnaire />} />
        <Route path="/thank-you" element={<ThankYou />} />
      </Routes>
      <FloatingButton />
      <Footer />
    </Router>
  );
}

export default App;