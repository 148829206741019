import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './tailwind.css';

const buttonClasses = "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800";

function Navbar() {
  const [isProgramOpen, setProgramOpen] = useState(false);
  const [isAboutUsOpen, setAboutUsOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSubMenuOpen, setSubMenuOpen] = useState(true);

  return (
    <nav className="bg-[#122533] fixed top-0 w-full z-50 shadow-md">
      <div className="flex justify-between items-center w-screen px-4 md:px-0">
        <Link to="/" className="text-[#FAFAFA] font-bold text-xl">
          <div className="flex items-center">
            <img src={process.env.PUBLIC_URL + '/CodeLands3.png'} alt="Code Lands" className='w-1/5' /> Code Lands
          </div>
        </Link>
        
        <button onClick={() => setMobileMenuOpen(!isMobileMenuOpen)} className="md:hidden my-3">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#FAFAFA" className="h-6 w-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>

        {isMobileMenuOpen && (
          <>
            <div className="fixed inset-0 bg-black opacity-50" onClick={() => setMobileMenuOpen(false)}></div>
            <div className="absolute top-0 left-0 h-screen w-3/4 bg-white shadow-md overflow-auto">
              <button onClick={() => setMobileMenuOpen(false)} className="absolute top-0 right-0 p-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div onClick={() => {setSubMenuOpen(!isSubMenuOpen)}} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                  Programs
                  {isSubMenuOpen ? 
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 3a1 1 0 01.707 1.707L7.414 8l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4A1 1 0 0110 3z" clipRule="evenodd" />
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 17a1 1 0 01-.707-1.707L12.586 12 9.293 8.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4A1 1 0 0110 17z" clipRule="evenodd" />
                    </svg>
                  }
                  {isSubMenuOpen && (
                    <div className="ml-4">
                      <Link to="/curriculum/little-coder" onClick={() => setMobileMenuOpen(false)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Little Coder</Link>
                      <Link to="/curriculum/junior-coder" onClick={() => setMobileMenuOpen(false)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Junior Coder</Link>
                      <Link to="/curriculum/senior-coder" onClick={() => setMobileMenuOpen(false)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Senior Coder</Link>
                    </div>
                  )}
                </div>
                <Link to="/pricing" onClick={() => setMobileMenuOpen(false)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Pricing</Link>
                <Link to="/about" onClick={() => setMobileMenuOpen(false)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">About Us</Link>
                <Link to="/contact" onClick={() => setMobileMenuOpen(false)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Contact</Link>
                <Link to="/careers" onClick={() => setMobileMenuOpen(false)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Careers</Link>
                <Link to="/faq" onClick={() => setMobileMenuOpen(false)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">FAQ</Link>
                <Link to="/login" onClick={() => setMobileMenuOpen(false)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Login</Link>
                <Link to="/demo" onClick={() => setMobileMenuOpen(false)} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Book Free Class</Link>
                {/* Add more links as needed */}
              </div>
            </div>
          </>
        )}

        <div className="grid grid-cols-3 justify-items-center items-center w-10/12 m-2 hidden md:grid">
          <div className="col-span-1 relative">
            <button 
              onMouseEnter={() => setProgramOpen(true)} 
              onMouseLeave={() => setProgramOpen(false)} 
              className="text-[#333] font-bold text-xl"
            >
              <Link to="/programs" className="text-[#FAFAFA] font-bold text-xl">
                Our Programs
              </Link>
            </button>

            {isProgramOpen && (
              <div 
                onMouseEnter={() => setProgramOpen(true)} 
                onMouseLeave={() => setProgramOpen(false)} 
                className="absolute left-0 pt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              >
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <Link to="/curriculum/little-coder" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Little Coder</Link>
                  <Link to="/curriculum/junior-coder" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Junior Coder</Link>
                  <Link to="/curriculum/senior-coder" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Senior Coder</Link>
                  {/* Add more links as needed */}
                </div>
              </div>
            )}
          </div>

          <div className="col-span-1 relative">
            <button
              onMouseEnter={() => setAboutUsOpen(true)}
              onMouseLeave={() => setAboutUsOpen(false)}
              className="text-[#FAFAFA] font-bold text-xl"
            >
              About Us
            </button>

            {isAboutUsOpen && (
              <div
                onMouseEnter={() => setAboutUsOpen(true)}
                onMouseLeave={() => setAboutUsOpen(false)}
                className="absolute left-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              >
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <Link to="/pricing" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Pricing</Link>
                  <Link to="/about" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">About Us</Link>
                  <Link to="/contact" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Contact</Link>
                  <Link to="/careers" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Careers</Link>
                  <Link to="/faq" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">FAQ</Link>
                  {/* Add more links as needed */}
                </div>
              </div>
            )}
          </div>

          <div className="col-span-1">
            <Link to="/login" className="text-[#333] font-bold text-xl">
              <button type="button" className={buttonClasses}>Login</button>
            </Link>

            <Link to="/demo" className="text-[#333] font-bold text-xl">
              <button type="button" className={buttonClasses}>Book Free Class</button>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;