import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../tailwind.css';
import './index.css';
import './programsList.css';
import codingImage from './assets/coding.svg'; // Example SVG image
import learningImage from './assets/learning.svg'; // Example SVG image
import benefitsImage from './assets/benefits.svg'; // Example SVG image

const buttonClasses = "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800";

const curriculum = [
    {
        title: "Game Development",
        description: "Learn how to create games using Unity game engine.",
        learningList: [
            "Unity game engine",
            "Game development",
            "Creative thinking"
        ],
        age: "Ages 8+",
        image: "gamedev/gamedev.png",
        link: "/programs/game-development"
    },
    {
        title: "Web Development",
        description: "Learn HTML, CSS, and JavaScript. Create websites and web applications.",
        learningList: [
            "HTML",
            "CSS",
            "JavaScript",
            "Web development"
        ],
        age: "Ages 8+",
        image: "webdev/webdev.png",
        link: "/programs/web-development"
    },
    {
        title: "Python",
        description: "Learn Python programming language. Create games, apps, and websites.",
        learningList: [
            "Python programming",
            "Game development",
            "Web development"
        ],
        age: "Ages 10+",
        image: "python/python.png",
        link: "/programs/python"
    },
    {
        title: "App Development",
        description: "Learn how to create mobile apps for Android and iOS devices.",
        learningList: [
            "App development",
            "Android",
            "iOS"
        ],
        age: "Ages 10+",
        image: "appdev/android.png",
        link: "/programs/app-development"
    }
];

function JuniorCoder() {
  useEffect(() => {
    const handleHashChange = () => {
      const { hash } = window.location;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const yOffset = -40; // Adjust this value based on your navbar height
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y });
        }
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <div className="bg-[#FAFAFA] pt-10 md:pt-16 flex min-h-screen">
      {/* Sidebar */}
      <div className="hidden lg:block w-64 h-screen sticky top-0 bg-white border-r border-gray-200 p-4 overflow-y-auto pt-20">
        <h2 className="font-bold mb-4">Table of Contents</h2>
        <ul className="space-y-2">
          <li><a href="#what-is-junior-coder" className="text-blue-600 hover:underline">What is Junior Coder?</a></li>
          <li><a href="#suitable-program" className="text-blue-600 hover:underline">Which program is suitable for my child?</a></li>
          <li><a href="#what-will-student-learn" className="text-blue-600 hover:underline">What will student learn?</a></li>
          <li><a href="#benefits" className="text-blue-600 hover:underline">Benefits</a></li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="flex-1 lg:mr-64 p-8">
        <div className="flex flex-col items-center w-full bg-sky-300 p-8 rounded-lg mb-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 items-center w-full">
            <div className="col-span-1 m-2 flex justify-center items-center">
              <img src={process.env.PUBLIC_URL + '/programs/JuniorCoder.png'} alt="Junior Coder" className='w-1/2 h-auto' />
            </div>
            <div className="col-span-1 m-5">
              <h1 className="text-black font-bold text-4xl">
                Junior Coder <br />(9-13 years old)
              </h1>
              <h2 className="text-black text-xl mb-4">
                Learn to code with fun and interactive activities.
              </h2>
              <Link to="/demo" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                Book Free Class
              </Link>
          </div>
        </div>
      </div>

        <div id="what-is-junior-coder" className="bg-white p-8 rounded-lg mb-8 shadow-md section">
          <div className="flex items-center mb-6">
            <img src={codingImage} alt="Coding" className="w-16 h-16 mr-4" />
            <h2 className="text-black font-bold text-4xl">
              What is Junior Coder?
            </h2>
          </div>
          <p className="text-black text-lg mb-4">
            Junior Coder is a program designed for children aged 9-13 years old who want to learn how to code.
            Students will learn the basics of coding through fun and interactive activities.
            The program is suitable for beginners and does not require any prior coding experience.
          </p>
        </div>

        <div id="suitable-program" className="bg-white p-8 rounded-lg mb-8 shadow-md section">
          <div className="flex items-center mb-6">
            <img src={learningImage} alt="Learning" className="w-16 h-16 mr-4" />
            <h2 className="text-black font-bold text-4xl">
              Which program is suitable for my child?
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {curriculum.map((program, index) => (
              <div key={index} className="flex flex-col border-2 border-gray-200 shadow-md p-4 hover:shadow-lg transition-shadow duration-300">
                <div className="flex w-full justify-between items-center mb-4">
                  <div className="flex-1">
                    <h2 className="text-[#333] text-2xl m-4 font-semibold text-left">
                      {program.title} <br></br>
                      <span className="text-sm text-gray-500">{program.age}</span>
                    </h2>
                  </div>
                  <div className="flex-1 flex justify-center">
                    <img src={process.env.PUBLIC_URL + '/programs/' + program.image} alt={program.title} className='w-1/2 h-auto' />
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <p className="text-[#333] text-lg m-4 text-left">
                    {program.description}
                  </p>
                  <div className="flex flex-wrap m-4">
                    {program.learningList.map((item, i) => (
                      <span key={i} className="bg-blue-100 text-blue-700 text-sm font-semibold mr-2 mb-2 px-3 py-1 rounded-full">
                        {item}
                      </span>
                    ))}
                  </div>
                  <Link to={program.link} className={`${buttonClasses} m-4`}>
                    Learn More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div id="what-will-student-learn" className="bg-white p-8 rounded-lg mb-8 shadow-md section">
          <div className="flex items-center mb-6">
            <img src={learningImage} alt="Learning" className="w-16 h-16 mr-4" />
            <h2 className="text-black font-bold text-4xl">
              What will student learn?
            </h2>
          </div>
          <p className="text-black text-lg mb-4">
            Students will learn the basics of coding, including:
          </p>
          <ul className="list-disc list-inside text-black text-lg mb-4">
            <li>Sequencing</li>
            <li>Loops</li>
            <li>Conditional statements</li>
            <li>Events</li>
            <li>Variables</li>
            <li>Functions</li>
          </ul>
        </div>

        <div id="benefits" className="bg-white p-8 rounded-lg mb-8 shadow-md section">
          <div className="flex items-center mb-6">
            <img src={benefitsImage} alt="Benefits" className="w-16 h-16 mr-4" />
            <h2 className="text-black font-bold text-4xl">
              Benefits of Junior Coder
            </h2>
          </div>
          <ul className="list-disc list-inside text-black text-lg mb-4">
            <li>One-on-one classes</li>
            <li>Certificate of completion</li>
            <li>Flexible schedules</li>
            <li>Experienced instructors</li>
            <li>Interactive learning environment</li>
            <li>Hands-on coding experience</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default JuniorCoder;