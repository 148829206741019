import React from 'react';
import { Link } from 'react-router-dom';
import '../tailwind.css';
import './programsList.css';

const buttonClasses = "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800";

const curriculum = [
    {
        title: "Little Coder (Ages 4-8)",
        description: "An introductory coding course for young children to learn basic programming concepts and improve problem-solving skills.",
        programList: [
            "Scratch",
            "Blockly",
            "Code.org"
        ],
        image: "LittleCoder.jpg",
        link: "/curriculum/little-coder"
    },
    {
        title: "Junior Coder (Ages 9-13)",
        description: "A coding course for children to learn intermediate coding concepts, game development, and web design.",
        programList: [
            "Scratch",
            "Python",
            "Web development",
            "Game development"
        ],
        image: "JuniorCoder.jpg",
        link: "/curriculum/junior-coder"
    },
    {
        title: "Senior Coder (Ages 14+)",
        description: "An advanced coding course for teenagers and adults to learn programming languages, app development, and real-world projects.",
        programList: [
            "Python",
            "Web development",
            "App development",
            "Game development",
            "Cybersecurity",
            "AI & Machine Learning"
        ],
        image: "SeniorCoder.jpg",
        link: "/curriculum/senior-coder"
    }
];

function CurriculumList() {
    return (
        <div className="bg-[#FAFAFA] py-10">
            {/* ---- curriculum ----- */}
            <div className="flex justify-center items-center w-screen">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-10/12 m-2">
                    <div className="col-span-1 md:col-span-2 lg:col-span-3 text-center">
                        <h1 className="text-[#333] font-bold text-4xl m-10">
                            Our curriculum
                        </h1>
                    </div>

                    {curriculum.map((program, index) => (
                        <div key={index} className="flex flex-col items-center border-2 border-gray-200 shadow-md p-4 hover:shadow-lg transition-shadow duration-300">
                            <div className="w-full flex justify-center mb-4">
                                <img src={process.env.PUBLIC_URL + '/homepage/' + program.image} alt={program.title} className='w-1/2 h-auto' />
                            </div>
                            <h2 className="text-[#333] text-2xl m-4 font-semibold text-center">
                                {program.title}
                            </h2>
                            <p className="text-[#333] text-lg m-4 text-center">
                                {program.description}
                            </p>
                            <div className="flex flex-wrap m-4">
                                {program.programList.map((item, i) => (
                                    <span key={i} className="bg-blue-100 text-blue-700 text-sm font-semibold mr-2 mb-2 px-3 py-1 rounded-full">
                                        {item}
                                    </span>
                                ))}
                            </div>
                            <Link to={program.link} className={`${buttonClasses}`}>
                                Learn More
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CurriculumList;