import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './tailwind.css';
import './faq.css';

const faq = [
  {
    question: "What is Code Lands?",
    answer: "Code Lands is an innovative online platform dedicated to teaching coding to children and teenagers. Our mission is to create a fun, engaging, and interactive learning environment where students can develop their coding skills. We offer a variety of courses that cater to different age groups and skill levels, ensuring that every student can find a course that suits their needs."
  },
  {
    question: "Who can enroll in Code Lands courses?",
    answer: "Our courses are designed for students of all ages, starting from as young as 4 years old to teenagers. We believe that it's never too early or too late to start learning how to code. Whether your child is a complete beginner or has some experience, we have courses that will help them grow and develop their skills."
  },
  {
    question: "What courses does Code Lands offer?",
    answer: "Code Lands offers a comprehensive range of courses, from foundational coding principles to advanced programming topics. Our curriculum includes courses on various programming languages such as Scratch, Python, JavaScript, and more. We also cover important concepts like game development, web design, and app creation, providing a well-rounded education in coding."
  },
  {
    question: "How much do Code Lands courses cost?",
    answer: "The cost of our courses varies depending on the specific course and its duration. We encourage you to contact us directly for detailed pricing information. Additionally, we offer a free trial course so you can experience our teaching methods firsthand. We also provide discounts for enrolling in multiple courses, making it more affordable to continue learning with us."
  },
  {
    question: "Who are the instructors at Code Lands?",
    answer: "Our instructors are highly experienced professionals with a strong background in computer science and education. They are passionate about teaching and are dedicated to helping students succeed. Each instructor brings a wealth of knowledge and practical experience to the classroom, ensuring that students receive high-quality instruction and support."
  },
  {
    question: "How do I enroll in a Code Lands course?",
    answer: "Enrolling in a Code Lands course is simple. Visit our website, browse through our course offerings, and select the course that interests you. You can then register for the course online and pay the enrollment fee. If you have any questions or need assistance, our support team is always available to help."
  },
  {
    question: "What is the duration of Code Lands courses?",
    answer: "Each session of a Code Lands course typically lasts 1 hour. The total duration of a course depends on the number of sessions and the specific content covered. We design our courses to be flexible and accommodating, allowing students to learn at their own pace while ensuring they gain a thorough understanding of the material."
  }
];

function Faq() {
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const toggleQuestion = (index) => {
    setSelectedQuestions((prevSelectedQuestions) =>
      prevSelectedQuestions.includes(index)
        ? prevSelectedQuestions.filter((i) => i !== index)
        : [...prevSelectedQuestions, index]
    );
  };

  return (
    <div className="bg-[#FAFAFA] py-10">
      <div className="max-w-screen-lg mx-auto px-4">
        <div className="text-center mb-10">
          <h1 className="text-[#333] font-bold text-4xl mb-4">Frequently Asked Questions</h1>
          <p className="text-[#333] text-lg">Here are some common questions about Code Lands and our coding courses. If you have any other questions, feel free to contact us.</p>
        </div>

        <div className="space-y-4">
          {faq.map((item, index) => (
            <div key={index} className="border-b border-gray-300 pb-4">
              <button
                className="w-full flex justify-between items-center text-left text-gray-700 text-xl font-semibold py-2 focus:outline-none"
                onClick={() => toggleQuestion(index)}
              >
                {item.question}
                <span className="text-2xl">
                  {selectedQuestions.includes(index) ? '-' : '+'}
                </span>
              </button>
              <CSSTransition
                in={selectedQuestions.includes(index)}
                timeout={300}
                classNames="faq-transition"
                unmountOnExit
              >
                <p className="text-gray-700 text-lg mt-2">{item.answer}</p>
              </CSSTransition>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faq;