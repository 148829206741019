import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../tailwind.css';
import './index.css';
import './programsList.css';
import codingImage from './assets/coding.svg'; // Example SVG image
import learningImage from './assets/learning.svg'; // Example SVG image
import benefitsImage from './assets/benefits.svg'; // Example SVG image

const buttonClasses = "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800";

const projects = [
    {
        title: "Sequencing",
        description: "Learn how to sequence instructions to create a simple animation.",
    },
    {
        title: "Loops",
        description: "Learn how to use loops to repeat a set of instructions.",
    },
    {
        title: "Conditional Statements",
        description: "Learn how to use conditional statements to make decisions in your code.",
    },
    {
        title: "Events",
        description: "Learn how to use events to trigger actions in your code.",
    },
    {
        title: "Variables",
        description: "Learn how to use variables to store and manipulate data in your code.",
    },
    {
        title: "Functions",
        description: "Learn how to use functions to organize your code into reusable blocks.",
    }
];

const projectSamples = [
    {
        title: "Art Studio",
        description: "Teaches students how to create art using code.",
        image: '/programs/codeorg/art-studio.png'
    },
    {
        title: "Maze",
        description: "Teaches students how to create a maze game.",
        image: '/programs/codeorg/maze.png'
    }
];

function CodeorgClass() {
    const [selectedProjects, setSelectedProjects] = useState([]);

    const toggleProject = (index) => {
        setSelectedProjects((prevSelectedProjects) =>
            prevSelectedProjects.includes(index)
                ? prevSelectedProjects.filter((i) => i !== index)
                : [...prevSelectedProjects, index]
        );
    };

    useEffect(() => {
        const handleHashChange = () => {
            const { hash } = window.location;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    const yOffset = -40; // Adjust this value based on your navbar height
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y });
                }
            }
        };

        window.addEventListener('hashchange', handleHashChange);
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    const renderCustomCarouselItem = (sample) => (
        <div key={sample.title} className="relative">
            <img src={process.env.PUBLIC_URL + sample.image} alt={sample.title} className='w-full h-96 object-cover' />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4">
                <h3 className="text-xl font-semibold">{sample.title}</h3>
                <p className="text-lg">{sample.description}</p>
            </div>
        </div>
    );

    const renderArrowPrev = (onClickHandler, hasPrev, label) =>
        hasPrev && (
            <button type="button" onClick={onClickHandler} title={label} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full">
                &#9664;
            </button>
        );

    const renderArrowNext = (onClickHandler, hasNext, label) =>
        hasNext && (
            <button type="button" onClick={onClickHandler} title={label} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full">
                &#9654;
            </button>
        );

    return (
        <div className="bg-[#FAFAFA] pt-10 md:pt-16 flex min-h-screen">
            {/* Sidebar */}
            <div className="hidden lg:block w-64 h-screen sticky top-0 bg-white border-r border-gray-200 p-4 overflow-y-auto pt-20">
                <h2 className="font-bold mb-4">Table of Contents</h2>
                <ul className="space-y-2">
                    <li><a href="#what-will-student-learn" className="text-blue-600 hover:underline">What will student learn?</a></li>
                    <li><a href="#projects" className="text-blue-600 hover:underline">Projects List</a></li>
                    <li><a href="#projects-samples" className="text-blue-600 hover:underline">Projects Samples</a></li>
                </ul>
            </div>

            {/* Main Content */}
            <div className="flex-1 2xl:mr-64 p-8">
                <div className="flex flex-col items-center w-full bg-sky-300 p-8 rounded-lg mb-8 2xl:px-32">
                    <div className="grid grid-cols-1 lg:grid-cols-3 items-center w-full">
                        <div className="col-span-2 m-5">
                            <div className="flex flex-col lg:flex-row items-center mb-8">
                                <h1 className="text-black font-bold text-4xl mb-4 lg:mb-0">
                                    Code.org
                                </h1>
                                <div className="ml-0 lg:ml-4 lg:flex-shrink-0">
                                    <img src={process.env.PUBLIC_URL + '/programs/codeorg/codeorg.png'} alt="Code.org" className='w-32 h-auto' />
                                </div>
                            </div>
                            <h2 className="text-black text-xl mb-4">
                                Learn the fundamentals of programming with Code.org.
                            </h2>
                        </div>

                        <div className="mt-6 text-center bg-white pb-8 md:p-8 rounded-lg shadow-md">
                            <div className="grid grid-cols-2 gap-4 mb-6">
                                <div className="p-4">
                                    <p className="text-gray-500 text-lg"><strong>Ages:</strong></p>
                                    <p className="text-black text-lg">4+</p>
                                </div>
                                <div className="p-4">
                                    <p className="text-gray-500 text-lg"><strong>Level:</strong></p>
                                    <p className="text-black text-lg">Beginner</p>
                                </div>
                                <div className="p-4">
                                    <p className="text-gray-500 text-lg"><strong>Requirement:</strong></p>
                                    <p className="text-black text-lg">PC</p>
                                </div>
                                <div className="p-4">
                                    <p className="text-gray-500 text-lg"><strong>Duration:</strong></p>
                                    <p className="text-black text-lg">16 Classes</p>
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <Link to="/demo" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                    Book Free Class
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="what-will-student-learn" className="bg-white p-8 rounded-lg mb-8 shadow-md section">
                    <div className="flex items-center mb-6">
                        <img src={learningImage} alt="Learning" className="w-16 h-16 mr-4" />
                        <h2 className="text-black font-bold text-4xl">
                            What will student learn?
                        </h2>
                    </div>
                    <p className="text-black text-lg mb-4">
                        Students will learn the fundamentals of programming with Code.org.
                        They will learn how to create simple programs using visual programming languages.
                        The program is designed to be hands-on and interactive, so students can learn by doing.
                    </p>
                </div>

                <div id="projects" className="bg-white p-8 rounded-lg mb-8 shadow-md section">
                    <div className="flex items-center mb-6">
                        <img src={benefitsImage} alt="Benefits" className="w-16 h-16 mr-4" />
                        <h2 className="text-black font-bold text-4xl">
                            Projects List
                        </h2>
                    </div>
                    <div className="space-y-4">
                        {projects.map((project, index) => (
                            <div key={index} className="border-b border-gray-300 pb-4">
                                <button
                                    className="w-full flex justify-between items-center text-left text-gray-700 text-xl font-semibold py-2 focus:outline-none"
                                    onClick={() => toggleProject(index)}
                                >
                                    {project.title}
                                    <span className="text-2xl">
                                        {selectedProjects.includes(index) ? '-' : '+'}
                                    </span>
                                </button>
                                <CSSTransition
                                    in={selectedProjects.includes(index)}
                                    timeout={300}
                                    classNames="faq-transition"
                                    unmountOnExit
                                >
                                    <p className="text-gray-700 text-lg mt-2">{project.description}</p>
                                </CSSTransition>
                            </div>
                        ))}
                    </div>
                </div>

                <div id="projects-samples" className="bg-white p-8 rounded-lg mb-8 shadow-md section">
                    <div className="flex items-center mb-6">
                        <img src={benefitsImage} alt="Benefits" className="w-16 h-16 mr-4" />
                        <h2 className="text-black font-bold text-4xl">
                            Projects Samples
                        </h2>
                    </div>
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        infiniteLoop
                        useKeyboardArrows
                        autoPlay
                        renderArrowPrev={renderArrowPrev}
                        renderArrowNext={renderArrowNext}
                    >
                        {projectSamples.map(renderCustomCarouselItem)}
                    </Carousel>
                </div>

                <div className="flex justify-center">
                    <Link to="/demo" className={buttonClasses}>
                        Book Free Class
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CodeorgClass;