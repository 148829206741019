import React from 'react';
import '../tailwind.css';
import './index.css';
import ContactButton from './contactbutton';

function Pricing() {
  return (
    <div className="bg-[#FAFAFA] pt-10 md:py-32">
      <div className="max-w-screen-lg mx-auto flex flex-col justify-center items-start w-full">
                {/* Pricing Section */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full">
          {/* Basic Tier */}
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <h2 className="text-2xl font-bold mb-4">Learner</h2>
            <p className="text-gray-600 mb-4">10 Classes</p>
            <p className="text-4xl font-bold mb-4">
              Rp 1.500.000
            </p>
            <p className="text-gray-400 mb-4">Price per meeting: Rp 150.000</p>

            <p className="text-gray-600 mb-4">
                The Learner plan is perfect for students who want to try out our classes and see if it's the right fit for them.
            </p>

            <p className="text-gray-400 mb-4">Estimated duration: 10 weeks*</p>
            <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
              Choose Plan
            </button>
          </div>

          {/* Popular Tier */}
          <div className="bg-white p-6 rounded-lg shadow-md text-center relative">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-yellow-400 text-white font-bold py-1 px-3 rounded-full">
              Popular
            </div>
            <h2 className="text-2xl font-bold mb-4">Achiever</h2>
            <p className="text-gray-600 mb-4">20 Classes</p>
            <p className="text-4xl font-bold mb-4">
              Rp 2.800.000
            </p>
            <p className="text-gray-400 mb-4">Price per meeting: Rp 140.000</p>
            <p className="text-gray-600 mb-4">
                The Achiever plan is perfect for students who are committed to learning and want to save more on the classes.
            </p>
            <p className="text-gray-400 mb-4">Estimated duration: 20 weeks*</p>
            <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
              Choose Plan
            </button>
            <p className="text-green-600 mb-4">Savings: Rp 200.000**</p>
          </div>

          {/* Premium Tier */}
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <h2 className="text-2xl font-bold mb-4">Professionals</h2>
            <p className="text-gray-600 mb-4">30 Classes</p>
            <p className="text-4xl font-bold mb-4">
              Rp 3.900.000
            </p>
            <p className="text-gray-400 mb-4">Price per meeting: Rp 130.000</p>
            <p className="text-gray-600 mb-4">
                The Professionals plan is perfect for students who are serious about learning and want to save the most on the classes.
            </p>
            <p className="text-gray-400 mb-4">Estimated duration: 30 weeks*</p>
            <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
              Choose Plan
            </button>
            <p className="text-green-600 mb-4">Savings: Rp 600.000**</p>
          </div>
        </div>

        {/* Notes Section */}
        <div className="w-full mt-8 text-center">
          <p className="text-gray-600 text-sm">
            *Estimated duration is based on 1 class per week
          </p>
          <p className="text-gray-600 text-sm">
            **Savings are calculated based on the total price of the classes compared to the Learner plan
          </p>
        </div>

        {/* 100% refund policy */}
        <div className="w-full mt-8 text-center bg-blue-50 p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4 text-blue-700">100% Refund Policy</h2>
          <p className="text-gray-600 mb-4">
            If you are not satisfied with our service, or if you are unable to continue the classes, we offer a 100% refund policy for the remaining classes.
            For example, if you have purchased the Achiever plan and have completed 10 classes, you can request a refund for the remaining 10 classes.
            Simply contact us and we will process your refund within 7 working days.
          </p>
        </div>

        {/* Flexible Schedule */}
        <div className="w-full mt-8 text-center bg-blue-50 p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4 text-blue-700">Flexible Schedule</h2>
          <p className="text-gray-600 mb-4">
            We understand that your schedule may change. If you need to reschedule a class,
            simply contact us at least 24 hours before the class starts and we will arrange a new time for you.
            No additional fees will be charged for rescheduling.
          </p>
          <p className="text-gray-600 mb-4">
            If your child is unable to attend a class, because of sickness, school activities, or other reasons,
            you can also pause the classes and resume them at a later time. Simply contact us and we will arrange the pause for you.
          </p>
        </div>

        {/* Satisfaction Guarantee */}
        <div className="w-full mt-8 text-center bg-blue-50 p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4 text-blue-700">Satisfaction Guarantee</h2>
          <p className="text-gray-600 mb-4">
            We are committed to providing the best learning experience for your child.
            If you are not satisfied with our service, please let us know and we will do our best to resolve the issue.
            Your satisfaction is our priority.
          </p>
        </div>

        {/* Certificate of Completion */}
        <div className="w-full mt-8 text-center bg-blue-50 p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4 text-blue-700">Certificate of Completion</h2>
          <p className="text-gray-600 mb-4">
            Upon completion of the classes, your child will receive a Certificate of Completion.
            This certificate will be a testament to your child's hard work and dedication to learning.
            It will also be a valuable addition to your child's portfolio.
          </p>
        </div>

        {/* Contact Us */}
        <div className="w-full mt-8 text-center bg-blue-50 p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4 text-blue-700">Contact Us</h2>
          <p className="text-gray-600 mb-4">
            If you have any questions or need further information, feel free to contact us. We are here to help you!
          </p>
          <ContactButton />
        </div>

      </div>
    </div>
  );
}

export default Pricing;